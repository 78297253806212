button,
.btn {
	border: 0;
	background-color: #2a913e;
	color: white;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 0.8rem;
	padding: 1px 35px;
	border-radius: 2px;
	line-height: 22px;
	text-decoration: none;
}
