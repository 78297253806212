.add-form {
	width: 800px;
	margin: 50px auto;
}

.add-form label {
	display: block;
	margin-top: 20px;
	margin-bottom: 10px;
	font-weight: bold;
}

.add-form label small {
	font-weight: normal;
}

.add-form input,
.add-form textarea {
	width: 100%;
	margin-top: 10px;
	padding: 10px;
}

.add-form button {
	padding: 10px;
	margin: 20px auto;
	width: 100%;
	cursor: pointer;
	font-size: 20px;
	letter-spacing: 2px;
}
